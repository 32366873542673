import React from "react";
import { Divider, Select } from "antd";
import openWindow from "@/script/openWindow.mjs";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import favicon from "/favicon.png";
import { DesktopOutlined } from "@ant-design/icons";


// import ShcemaDataPreview from './schemaDataPreview'

const cssLayoutHeader = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 0,
});
const cssLayoutHeaderLeft = css({
  fontSize: 20,
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  alignItems: "center",
  flexGrow: "1",
  flexShrink: "0",
  flexBasis: 0,
});
const cssLayoutHeaderCenter = css({
  fontSize: 14,
  fontWeight: "bold",
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: "1",
  flexShrink: "0",
  flexBasis: 0,
  gap: 12,
});
const cssLayoutHeaderRight = css({
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  flexGrow: "1",
  flexShrink: "0",
  flexBasis: 0,
  gap: 12,
});
const cssLink = css({
  fontWeight: "bold",
  color: "#fff",
  display: "flex",
  alignItems: "center",
});
const cssLogo = css({
  height: 22,
  marginRight: 8,
});
const cssDivider = css({
  borderColor: "#fff",
});
const cssRibbonButton = css({
  fontSize: 14,
  fontWeight: "bold",
  color: "#fff",
  "&:hover": {
    color: "#0099CC",
  },
});
export const Header = ({ isTemplate, uvObj, setScaleNum }) => {
  const handleChange = (val) => {
    setScaleNum(val);
  };

  // const fileId = uvObj?.uvMap && JSON.parse(uvObj?.uvMap || '') ? JSON.parse(uvObj.uvMap).fileId : undefined;
  // const fileIdData = uvObj?.demoMap && JSON.parse(uvObj?.demoMap) ? JSON.parse(uvObj?.demoMap || '')[0] : undefined
  // const fileId = fileIdData?.data?.fileId;

  const openUiDetail = () => {
    openWindow("/UiDetail", {
      uiId: uvObj.uiId,
      uiName: uvObj.uiName,
    });
  };

  const exportImage = async () => {    
    if(uvObj.fileId) {
      window.open(
        `${import.meta.env.VITE_HTTP_BASE_URL}/files/${uvObj.fileId}`,
        '_blank'
      )
    }
}

  return (
    <Layout.Header css={cssLayoutHeader}>
      <div css={cssLayoutHeaderLeft}>
        <Link css={cssLink} reloadDocument to="/" title={"进入工作台"}>
          <img css={cssLogo} alt="logo" src={favicon} />
          软件逻辑地图
        </Link>
        <Divider type="vertical" css={cssDivider} />
        <span style={{ fontSize: 14, fontWeight: "bold" }}>在线预览</span>
      </div>

      <div css={cssLayoutHeaderCenter}>
        <DesktopOutlined />
        {uvObj.uvName}
        {uvObj.uvType == 'MOBILE' &&
        <Select
          defaultValue={1}
          bordered={false}
          style={{ color: "#fff", width: 80 }}
          showArrow={false}
          size="small"
          onChange={handleChange}
          options={[
            {
              value: 0.35,
              label: "35%",
            },
            {
              value: 0.5,
              label: "50%",
            },
            {
              value: 0.8,
              label: "80%",
            },
            {
              value: 1,
              label: "100%",
            },
            {
              value: 1.2,
              label: "120%",
            },
            {
              value: 1.5,
              label: "150%",
            },
            {
              value: 2,
              label: "200%",
            },
          ]}
        />}
      </div>

      <div css={cssLayoutHeaderRight}>
        {!isTemplate && (
          <>
            <a onClick={openUiDetail} css={cssRibbonButton}>
              查看用户界面
            </a>
            {uvObj.fileId && <a onClick={exportImage} css={cssRibbonButton}>导出图片</a>}
          </>
        )}
      </div>
    </Layout.Header>
  );
};