import React, { useEffect, useState, useRef } from 'react';
import { Form, Row, Col, Input, Button, Modal, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SelectFmDialog from '@/pages/MainPage/dialogs/SelectFmDialog_Old';
// import Table from '@/pages/MainPage/components/Table';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Page from '@/script/Page';

// 系统功能选择框
const Pane = ({ visible, setVisible, onCall, prjId, sVer, isSingle = true, ucFlag, ...props }) => {
    const http = useHTTP();
    const [form] = Form.useForm();
    const { Search } = Input;

    const ref = useRef({});
    const [dataList, setDataList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visibleFm, setVisibleFm] = useState(false);

    useEffect(() => {
        if (visible) {
            ref.current.fmIds = null;
            form.resetFields();
            setSelectedList([]);
            doSearch();
        }
    }, [visible]);

    const doSearch = async () => {
        try {
            setLoading(true);

            const formData = form.getFieldsValue();
            formData.prjId = prjId;
            formData.sVer = sVer;
            formData.fmIds = ref.current.fmIds;
            formData.sfStsCodes = ['RLS', 'REVISE'];

            const url = ucFlag ? '/rmucs' : '/rmsfs';
            const data = await runAsync(
                async () => {
                    return await http.get(url, { ...formData, ...props });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: true },
                success: { show: false },
            });

            setDataList(data ?? []);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const doReset = () => {
        ref.current.fmIds = null;
        form.resetFields();
        doSearch();
    }

    const doConfirm = () => {
        if (selectedList.length == 0) {
            return;
        } else {
            setVisible(false);
            onCall(selectedList);
        }
    }

    const callFm = (value) => {
        if (value) {
            ref.current.fmIds = [value.fmId];
            form.setFieldsValue({ fmName: value.fmName });
        } else {
            ref.current.fmIds = null;
            form.setFieldsValue({ fmName: '' });
        }
    }

    const columns = [
        {
            title: '功能域',
            dataIndex: 'fmNamePath',
        },
        // {
        //     title: '功能类型',
        //     dataIndex: 'sfTypeName',
        // },
        {
            title: ucFlag ? '用例编号' : '功能编号',
            dataIndex: 'sfNo',
        },
        {
            title: ucFlag ? '用例名称' : '功能名称',
            dataIndex: 'sfName',
        },
    ];

    const rowSelection = {
        type: isSingle ? 'radio' : 'checkbox',
        selectedRowKeys: selectedList.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedList(selectedRows);
        },
    }

    return (
        <Modal
            visible={visible}
            width={1200}
            title={ucFlag ? '选择集成用例' : '选择系统功能'}
            okText="确认"
            cancelText="取消"
            onOk={doConfirm}
            onCancel={() => setVisible(false)}
            bodyStyle={{ background: '#f0f2f5', padding: '12px' }}
        >
            <div style={{ padding: '12px 12px 0px 12px', background: '#fff', height: '500px' }}>
                {/* 查询条件 */}
                <div style={{ padding: '0px 12px 0px 12px', overflow: 'hidden', background: '#fff' }}>
                    <Form form={form} onFinish={doSearch}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item name="fmName" label="功能域">
                                    <Search onSearch={() => setVisibleFm(true)} placeholder="请选择" readOnly />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name='sfNo' label={ucFlag ? '用例编号' : '功能编号'}>
                                    <Input placeholder="请输入" allowClear />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="sfName" label={ucFlag ? '用例名称' : '功能名称'}>
                                    <Input placeholder="请输入" allowClear />
                                </Form.Item>
                            </Col>

                            <Col span={6} style={{ textAlign: 'right' }}>
                                <Button onClick={doReset} style={{ margin: '0 8px' }}>重置</Button>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {/* 数据表格 */}
                <div style={{ marginTop: '0px', overflow: 'auto', background: '#fff' }}>
                    <Table
                        size="middle"
                        loading={loading}
                        columns={columns}
                        dataSource={dataList}
                        pagination={Page.init(dataList)}
                        rowKey={record => record.pkid}
                        rowSelection={{ ...rowSelection }}
                        scroll={{ y: 330 }}
                    />
                </div>
            </div>

            <SelectFmDialog visible={visibleFm} setVisible={setVisibleFm} onCall={callFm} prjId={prjId} sVer={sVer} isLeaf={true} />
        </Modal>
    );
}

export default Pane;
