import { Button } from 'antd';
import React, { useEffect, useState, useImperativeHandle, forwardRef} from 'react';
import useApi from "../useApi.mjs";
import SoftwareMap from "@/components/SoftwareMap/SoftwareMap.jsx";
import Modal from "@/components/Modal/Modal";

export default forwardRef(({}, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mapData, setMapData] = useState({});
    const api = useApi();

    useImperativeHandle(ref, () => ({handleCtrlClick}));
    const handleCtrlClick = async (obj) => {
        const { type, id } = obj;
        if (type == "UE") {
          const res = await api.fetchUeMapJson({ pkid: id });
          const { ueMap, ...data } = res;
          const {
            desgAcc,
            pkid: prjId,
            prjName,
            prjNo,
            prjTypeCode,
            rev,
            subTypeCode,
            typeName,
          } = await api.fetchProject({ prjId: data.prjId });
  
          const root = {
            ...data,
            bizNodeType: "UE",
            children: JSON.parse(ueMap),
          };
          setMapData({
            root,
  
            data: {
              desgAcc,
              prjId,
              prjName,
              prjNo,
              prjTypeCode,
              rev,
              subTypeCode,
              typeName,
            },
          });
          showModal();
        } else if (type == "ALG") {
          api
            .fetchAlgMapJson({
              pkid: id,
            })
            .then((res) => {
              const { algMap, ...data } = res;
              const newData = {
                ...data,
                bizNodeType: "UIALG",
                children: JSON.parse(algMap),
              };
              setMapData({
                root: newData,
              });
              showModal();
            })
            .catch((err) => {
              message.error(err.message);
            });
        }
    };
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
          title="界面能力"
          open={isModalOpen}
          width={"70%"}
          bodyStyle={{ height: "calc(78vh - 100px)" }}
          centered
          destroyOnClose
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              关闭
            </Button>,
          ]}
        >
          <SoftwareMap mapData={mapData} />
        </Modal>
    )
})