import "antd/dist/antd.less";
import React, { useMemo, useEffect, useState, useRef } from "react";
import { Divider, Select, Menu, Layout } from "antd";
import "./styles.less";
import { Link, useNavigation, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import favicon from "/favicon.png";
import PreviewContent from './components/PreviewContent'
import PreviewImage from "./components/PreviewImage";
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import { message } from "antd";
import {Header as PreviewHeader} from './components/Header.jsx'
import { getNavObj, processItem, findMenuItem } from './util.js'


const cssLayoutHeader = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 0,
});
const cssLayoutHeaderLeft = css({
  fontSize: 20,
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  alignItems: "center",
  width: '300px',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});
const cssLayoutHeaderCenter = css({
  fontSize: 14,
  fontWeight: "bold",
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 12,
});
const cssLayoutHeaderRight = css({
  color: "#fff",
  flexGrow: "1",
  flexShrink: "0",
  flexBasis: 0,
  display: 'flex',
  justifyContent: 'end'
//   gap: 12,
});
const cssLink = css({
  fontWeight: "bold",
  color: "#fff",
  display: "flex",
  alignItems: "center",
});
const cssLogo = css({
  height: 22,
  marginRight: 8,
});
const cssDivider = css({
  borderColor: "#fff",
});

function processArray(arr) {
  let firstUVKey = null;

  function traverse(obj) {
      if (Array.isArray(obj)) {
          for (let item of obj) {
              traverse(item);
          }
      } else if (typeof obj === 'object' && obj !== null) {
          if (obj.type === 'UM' && (!obj.children || obj.children.length == 0)) {
            obj.key = obj.uiId
          }
          if (obj.type === 'UM' && (!obj.children || obj.children.length == 0) && (!obj.images || obj.images.length == 0)) {
              obj.disabled = true;
          } else if ( firstUVKey === null && !obj.children) {
              firstUVKey = obj;
          }

          for (let key in obj) {
              if (obj.hasOwnProperty(key)) {
                  traverse(obj[key]);
              }
          }
      }
  }

  traverse(arr);

  return firstUVKey;
}

const Header = ({ current, uvType, uvObj, menuData, setScaleNum, handleMenuChange }) => {
  // const [current, setCurrent] = useState(typeList[0].items[0].key);
  const [frameKey, setFrameKey] = useState(menuData[0]?.key)
  const [loading, setLoading] = useState(false)
  const items = menuData.find((item) => item.key == frameKey)?.children.map(processItem)
  useEffect(() => {
    setFrameKey(menuData[0]?.key)
  }, [menuData])

  const handleChange = (val) => {
    setScaleNum(val);
  };

  const handleMenuClick = (e) => {
    const targetItem = findMenuItem(items, e.key)
    const customData = targetItem?.custom;
    handleMenuChange(e.key, {
      key: e.key,
      type: customData.type,
      images: customData.images || [],
      uiId: customData.uiId
    })
  };

  const handleFrameKeyChange = (key) => {
    if (key == frameKey) return;
    setLoading(true)
    const items = menuData.find(item => item.key == key).children;
    const firstUVKey = processArray(items);
    if (!firstUVKey) {
      message.warning("当前项目没有可用的视图")
    } else {
      handleMenuChange(firstUVKey.key, {
        key: firstUVKey.key,
        type: firstUVKey.type,
        uiId: firstUVKey.uiId,
        images: firstUVKey.images || []
      })
    }
    setFrameKey(key)
    setTimeout(()=> {
      setLoading(false)
    }, 200)
  }

  return (
    <Layout.Header css={cssLayoutHeader}>
      <div css={cssLayoutHeaderLeft}>
        <Link css={cssLink} reloadDocument to="/" title={"进入工作台"}>
          <img css={cssLogo} alt="logo" src={favicon} />
          软件逻辑地图
        </Link>
        <Divider type="vertical" css={cssDivider} />
        <span style={{ fontSize: 14, fontWeight: "bold" }}>在线预览</span>
      </div>

      <div css={cssLayoutHeaderCenter}>
        {menuData && menuData.length > 0 && <Select
          value={frameKey}
          dropdownMatchSelectWidth={false}
          style={{ width: 180 }}
          options={menuData.map((item) => ({
            value: item.key,
            label: item.label,
          }))}
          onChange={handleFrameKeyChange}
        ></Select>}
        {uvType == 'UV' && <span title={uvObj.uvName} className="header_uvname">
          {uvObj.uvName}
        </span>}

        {uvObj.uvType == "MOBILE" && (
          <Select
            defaultValue={1}
            bordered={false}
            style={{ color: "#fff", width: 58 }}
            showArrow={false}
            size="small"
            onChange={handleChange}
            options={[
              {
                value: 0.35,
                label: "35%",
              },
              {
                value: 0.5,
                label: "50%",
              },
              {
                value: 0.8,
                label: "80%",
              },
              {
                value: 1,
                label: "100%",
              },
              {
                value: 1.2,
                label: "120%",
              },
              {
                value: 1.5,
                label: "150%",
              },
              {
                value: 2,
                label: "200%",
              },
            ]}
          />
        )}
      </div>

      <div css={cssLayoutHeaderRight}>
        {!loading && <Menu
          theme="dark"
          activeKey={frameKey}
          onClick={handleMenuClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items?.map(item => ({label: item.label, key: item.key, children: item.children}))}
        />}
      </div>
    </Layout.Header>
  );
};

const FormilyFrame = (props) => {
  const params = new URLSearchParams(location.search.split("?")[1]);
  const uvMode =  params.get("uvMode") ? Number(params.get("uvMode")) : 2;
  const isTemplate = uvMode != 2;
  const [uvObj, setUvObj] = useState({});
  const [current, setCurrent] = useState(null);
  const [currentType, setCurrentType] = useState(params.get('uvId') ? 'UV' : 'UM')
  const [uiId, setUiId] = useState(null)
  // const history = useHistory();
  const http = useHTTP();
  const [menuData, setMenuData] = useState([])
  const [umImages, setUmImages] = useState([])

  const [scaleNum, setScaleNum] = useState(1);
  const navigation = useNavigation();
  const navigate = useNavigate();

  const handlePopState = (event) => {
    if (event.state) {
      const uvType = params.get('uvId') ? 'UV' : 'UM'
      const id = uvType == 'UV' ? params.get('uvId') : params.get('uiId')
      if (uvType != currentType) {
        setCurrentType(uvType)
      }
      if (id != current) {
        setCurrent(id)
      }
    }
  };
  useEffect(() => {
    if (navigation.state === 'idle') {
      handlePopState(navigation)
    }
  }, [navigation.state]);

  useEffect(() => {
    doQuery()
  }, [params.get('prjId'), params.get('sVer')])

  const doQuery = async () => {
    const prjId = params.get('prjId');
    const sVer = params.get('sVer') || 1;
    if (!prjId) return;
    const data = await runAsync(
        async () => {
            return await http.get("/archuas/loadUvtree", {
              prjId,
              sVer
            })
        },
        {
            error: {content: (err) => `查询菜单失败: ${err.message}`},
            loading: {show: false},
            success: {show: false},
        }
    );

    if (data && data.length > 0) {
        processArray(data);
        const firstUVKey = processArray(data[0].children)
        // setCurrent(firstUVKey)
        if (!firstUVKey) {
          message.warning("当前项目没有可用的视图")
        } else {
          if(!params.get("uvId") && !params.get("uiId")) {
            
            handleMenuChange(firstUVKey.key, {
                key: firstUVKey.key,
                type: firstUVKey.type,
                uiId: firstUVKey.uiId,
                images: firstUVKey.images || []
            })
          } else {
            setCurrent
          }
        }
        
        setMenuData(data);
    }
}

  const handleMenuChange = (id, option={type: 'UV'}) => {
    setCurrentType(option.type)
    // const iframeWindow = iframeRef.contentWindow
    setCurrent(id)

    if (option.type == 'UM') {
      const images = option.images.slice(0,1);
      const uiId = option.uiId
      const navObj = getNavObj(uiId, 'PIG', images)
      navigate(navObj);
    }
  }

  const handlePageChange = (id, type = undefined) => {
    setCurrent(id)
    if(type) {
      setCurrentType(type)
    }
  }


  return (
    <div className="formilyFrame" >
        {
            params.get('prjId') ? 
            <Header
                current={current}
                setScaleNum={setScaleNum}
                menuData={menuData}
                uvObj={uvObj}
                handleMenuChange={handleMenuChange}
            />: 
            <PreviewHeader
                setScaleNum={setScaleNum}
                isTemplate={isTemplate}
                uvObj={uvObj}
            />
        }
      
      {currentType == "UM" ? (
        <PreviewImage uiId={uiId} images={umImages} handlePageChange={handlePageChange} />
      ) : (
        <PreviewContent
          current={current}
          uvType={currentType}
          scaleNum={scaleNum}
          handlePageChange={handlePageChange}
          setUvObj={setUvObj}
        />
      )}
      
    </div>
  );
};

export default FormilyFrame;
