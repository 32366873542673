import React, { useEffect, useState, useRef} from 'react';
import useApi from "../useApi.mjs";
import Hexagon from './Hexagon.jsx'
import EventModal from './EventModal.jsx';
import { useNavigate, useNavigation  } from "react-router-dom";
import './previewImage.less'
import { getNavObj } from '../util.js'

const EventBtn = ({btnData, modalRef}) => {
  const [bgSvg, setBgSvg] = useState('');
  const elementRef = useRef(null);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false)
  const api = useApi();

  useEffect(() => {
    if (elementRef.current) {
      // 首次获取宽度
      const width = elementRef.current.offsetWidth;
      setBgSvg(Hexagon(width))
    }
  }, [])

  const handleFetchUv = async (pkid) => {
    const res = await api.fetchUvByUe({pkid})
    if(!res || res.length == 0) {
      const width = elementRef.current.offsetWidth;
      setBgSvg(Hexagon(width, 30, '#f5f5f5', '#d9d9d9'))
      setDisabled(true)
      return;
    }
    if(res.length == 1) {
      const uvData = res[0];
      let navObj; 
      if(uvData.demoType == 'IMG') {
        const uvMap = JSON.parse(uvData.demoMap) || [];
        const image = uvMap[0]?.data?.fileId
        
        navObj = getNavObj(uvData.uiId, 'PIG', [image])
        if(navObj) {
          navigate(navObj);
        }
      }
      if(uvData.demoType == 'DEMO') {
        navObj = getNavObj(uvData.pkid)
        navigate(navObj);
      }
      
    }else{
      modalRef.current?.handleCtrlClick({
        type: 'UE',
        id: pkid
      })
    }
  }
  return (<div 
    ref={elementRef}
    style={{ color: disabled ? '#00000040': '',display: 'inline-block', width:'max-content', backgroundImage: `url('${bgSvg}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', padding: '2px 16px', lineHeight: '26px', marginBottom: '20px', cursor: 'pointer'}}
    onClick={(e) => {
      if (e.ctrlKey) {
        // setIsModalOpen(true)
        // setMapData(btn.ueMap)
        modalRef.current?.handleCtrlClick({
          type: 'UE',
          id: btnData.pkid
        })
      } else {
        handleFetchUv(btnData.pkid)
      }
    }}
  >
    {btnData.ueName}
  </div>)
}

// 图片预览组件（左侧图片，右侧操作按钮）
export default () => {
  const api = useApi();
  const [current, setCurrent] = useState('')
  const params = new URLSearchParams(location.search.split("?")[1]);
  const [actionData, setActionData] = useState([]);
  const modalRef = useRef(null)
  const navigation = useNavigation();
  const umImages = params.get('images')?.split(',') ?? []

  useEffect(() => {
    if (navigation.state === 'idle') {
      handlePopState()
    }
  }, [navigation.state]);

  const handlePopState = () => {
    const params = new URLSearchParams(location.search.split("?")[1]);
    const currentUvId = params.get("uiId");
    if (!currentUvId) return;
    fetchData(currentUvId);
  };

  const fetchData = async (id) => {
    if(!id || id == current) {
      return
    }
    setActionData([]);
    const data = await api.fetchUmues({uiId: id});
    setCurrent(id)
    setActionData(data || []);
  };

  return (
    <div className='preview-image'>
      {/* 图片预览区域 */}
      <div className='left-box' >
        {
          umImages.map(item => (
            <img 
              key={item}
              className='leftImg-box'
              src={`${import.meta.env.VITE_HTTP_BASE_URL}/files/${item}`} 
              alt="预览图"
            />
          ))
        }
      </div>

      {/* 操作按钮区域 */}
      <div className='right-box' >
        {actionData.map((btn, index) => (
          <EventBtn btnData={btn} modalRef={modalRef} fetchData={fetchData} key={index}/>
        ))}
      </div>
      <EventModal ref={modalRef} />
    </div>
  );
};