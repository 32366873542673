export const getNavObj = (id, type='DEMO', images = []) => {
    const pathName = '/FormilyPreview'
    let urlParams = new URLSearchParams(location.search);

    if (type == 'DEMO') {
        urlParams.delete('uiId')
        urlParams.delete('images')
        urlParams.set('uvId', id);
    } else {
         // 设置新的id值
         urlParams.delete('uvId')
         urlParams.set('uiId', id);
         urlParams.set('images', images.join(','));
    }
    
    return {
        pathName,
        search: urlParams.toString()
    }
}

// 对Menu的数据进行处理，将除了label、key之外的值放入custom，并对可能存在的chilren做递归处理
export const processItem = ({ label, key, children, ...otherItem }) => {
    const item = { key, label, custom: { ...otherItem } };
    if (children) {
      item.children = children.map(processItem); // 递归处理子节点
    }
    return item;
  };

// 根据Menu当前点击的key的值，查找menuItems里对应的值，考虑children的可能性
export const findMenuItem = (items, key) => {
    for (const item of items) {
      if (item.key === key) return item
      if (item.children) {
        const found = findMenuItem(item.children, key)
        if (found) return found
      }
    }
    return null
}