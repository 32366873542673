import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        fetchUeMapJson: (args) => http.get(`/umuis/ue/${args.pkid}`),
        fetchAlgMapJson: (args) => http.get(`/umuis/alg/${args.pkid}`),
        fetchProject: (args) => http.get(`/prjs/${args.prjId}/info`),
        // exportImage: (params) => http.post('/convert-uv/image', params)
        fetchUmues: (args) => http.get(`/umues`, args),
        fetchUvByUe: (args) => http.get(`/umuis/getUvByUe`, args),
    }
}
