import {Menu, Modal} from 'antd'
import {CustomerServiceFilled, CustomerServiceTwoTone, HeartFilled, LoadingOutlined, MessageFilled, SecurityScanFilled, SmileTwoTone, ThunderboltFilled, UserOutlined} from '@ant-design/icons'
import {useUser, logout} from '@/biz/user.mjs'
import openWindow from '@/script/openWindow.mjs'
import mainMenuData from './mainMenuData';
import answer from '/answer.png';
import helpdoc from '/help_doc.png';
import usermanual from '/usermanual.png';
import drawicon from '/drawicon_42.png';
import runAsync from "@/script/runAsync.mjs";
import useHTTP from "@/hooks/useHTTP.mjs";
import React, {useEffect, useState} from "react";
import Icon from "@ant-design/icons";
import * as antIcons from "@ant-design/icons";
import {nanoid} from "nanoid";

const MenuItem = ({title, path, checkPrjType, ...props}) => {
    const user = useUser()

    const handleClick = e => {
        const target = e.domEvent.altKey ? '_self' : '_blank'

        if (checkPrjType) {
            const {prjTypeCode} = user;
            if (prjTypeCode == 'ONLINE') {
                Modal.info({title: '提示信息', content: '当前项目是布署项目，本模型视图不适用'});
            } else {
                openWindow(`/${path}`, {}, target)
            }
        } else {
            openWindow(`/${path}`, {}, target)
        }
    }

    return (
        <Menu.Item
            {...props}
            onClick={handleClick}
        >{title}</Menu.Item>
    )
}

const LogoutMenuItem = (props) => {

    return (
        <Menu.Item
            {...props}
            onClick={logout}
        >退出登录</Menu.Item>
    )
}

const PersonalCenterMenuItem = (props) => {
    const handleClick = e => {
        const target = e.domEvent.altKey ? '_self' : '_blank'
        openWindow('/PersonalCenter', {}, target)
    }

    return (
        <Menu.Item
            {...props}
            onClick={handleClick}
        >个人中心</Menu.Item>
    )
}

const MainMenu = () => {
    const user = useUser()
    const http = useHTTP();
    const [menuExtensions, setMenuExtensions] = useState([])
    const [menuExtensionLoading, setMenuExtensionLoading] = useState(true)

    const handleMenuExtensionsClick = (url) => {
        window.open(url)
    }

    const mapMenuExtensions = ({menuCode, menuIcon, menuIconColor, menuName, menuPath, children}) => {
        if (children) {
            return (
                <Menu.SubMenu
                    key={menuCode}
                    title={menuName}
                    icon={menuIcon ? <Icon style={{fontSize: 14, color: menuIconColor ?? 'whitesmoke'}} component={antIcons[menuIcon]}/> : null}
                >
                    {children.map(mapMenuExtensions)}
                </Menu.SubMenu>
            )
        } else {
            return (
                <Menu.Item
                    key={menuCode}
                    icon={menuIcon ? <Icon style={{fontSize: 14, color: menuIconColor ?? 'whitesmoke'}} component={antIcons[menuIcon]}/> : null}
                    onClick={() => {
                        console.log('menuPath',menuPath)
                        handleMenuExtensionsClick(menuPath)
                    }}
                >
                    {menuName}
                </Menu.Item>
            )
        }
    }

    const doQueryMenuExtensions = async () => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.get(`/basMenuExtension/listEnableBasMenuExtension`, {});
                }, {
                    error: {content: (err) => `查询扩展菜单失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                });
            if (data) {
                setMenuExtensions(data);
            }
        } finally {
            setMenuExtensionLoading(false)
        }
    }

    const mapMenuItems = (items) => {
        const mapMenuItem = ({children, code, disabled, title, path, checkPrjType}) => {
            if (
                '1' === user?.isPlatformAdm ||
                'SM' !== code
            ) {
                if (children) {
                    return (
                        <Menu.SubMenu
                            key={title}
                            title={title}
                        >
                            {mapMenuItems(children)}
                        </Menu.SubMenu>
                    )
                } else {
                    return (
                        <MenuItem
                            disabled={disabled}
                            key={path}
                            title={title}
                            path={path}
                            checkPrjType={checkPrjType}
                        />
                    )
                }
            }
        }

        return items
            .map(mapMenuItem)
            .filter((a) => a)
    }

    const cssMenu = {
        justifySelf: 'end',
        flexWrap: 'wrap',
    }

    useEffect(() => {
        doQueryMenuExtensions()
    }, []);

    return (
        <Menu
            css={cssMenu}
            mode="horizontal"
            selectable={false}
            theme="dark"
            subMenuCloseDelay={0.3}
        >
            {mapMenuItems(mainMenuData)}

            {
                menuExtensionLoading && <Menu.SubMenu key={nanoid()} icon={<LoadingOutlined/>}></Menu.SubMenu>
            }

            {
                !menuExtensionLoading && menuExtensions && menuExtensions.length > 0 &&  menuExtensions.map(mapMenuExtensions)
            }

            <Menu.SubMenu icon={<UserOutlined/>} key="Personal" title={user?.name}>
                <PersonalCenterMenuItem key="PersonalCenter"/>
                <LogoutMenuItem key="Logout"/>
            </Menu.SubMenu>
        </Menu>
    )
}

export default MainMenu
