// import "antd/dist/antd.less";
import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from "react";
import { PreviewWidget } from "@/pages/MainPage/frames/Formily/widgets";
import Iframe from "@/pages/MainPage/frames/FormilyMobile/FormilyIframe";
import { useSchemaService } from "@/pages/MainPage/frames/Formily/service";
import { transformToTreeNode } from "@designable/formily-transformer";
import "../styles.less";
import { Link, useNavigate } from "react-router-dom";
import { getNavObj } from '../util.js'
import EventModal from './EventModal.jsx';


const PreviewContent = ({ current, scaleNum, handlePageChange, setUvObj, ...props }) => {
    const params = new URLSearchParams(location.search.split("?")[1]);
    const uvMode = params.get("uvMode") ? Number(params.get("uvMode")) : 2;
    const schemaService = useSchemaService(uvMode);
    const [shcemaData, setSchemaData] = useState({});

    const iframeRef = useRef(null);
    const modalRef = useRef(null)
    const navigate = useNavigate();

    const handlNavigate = (obj) => {
      // 将查询字符串分割成键值对
      const queryParams = obj.search?.split("&");
      // 创建一个空对象来存储结果
      const result = {};
      // 遍历键值对，并将其添加到对象中
      queryParams.forEach((param) => {
        const [key, value] = param.split("=");
        result[key] = decodeURIComponent(value);
      });
      navigate(obj);
      fetchData(result.uvId);
    };

    const jsonObject = {};
    for (const [key, value] of params.entries()) {
      jsonObject[key] = value;
    }

    // 添加一个新的状态到历史栈顶
    const pushToHistory = (id) => {
      const navObj = getNavObj(id)
      handlNavigate(navObj);
    };

    useEffect(() => {
      // 添加事件监听器
      window.addEventListener("popstate", handlePopState);

      // 返回一个清理函数，用于移除事件监听器
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, []);

    useEffect(() => {
      if (!current && !params.get("uvId")) return;
      if(params.get("uvId") && current && params.get("uvId") == current) return;
      const uvId = current || params.get("uvId")
      const navObj = getNavObj(uvId)
      handlNavigate(navObj);
    }, [current])

    const handlePopState = (event) => {
      if (event.state) {
        const params = new URLSearchParams(location.search.split("?")[1]);
        const currentUvId = params.get("uvId");
        fetchData(currentUvId);
      }
    };

    const fetchData = async (id) => {
      if(!id) return;
      handlePageChange && handlePageChange(id);
      const data = await schemaService.loadSchema(undefined, id) ?? {};
      setSchemaData(data);
      const fileIdData =
        data?.demoMap && JSON.parse(data?.demoMap)
          ? JSON.parse(data?.demoMap || "")[0]
          : undefined;
      const fileId = fileIdData?.data?.fileId;
      setUvObj({
        uvType: data.uvType,
        uvName: data.uvName,
        fileId,
        uiId: data.uiId,
        uiName: data.uiName
      });
    };

    const handleCtrlClick = async (obj) => {
      const { type, id } = obj;
      modalRef.current?.handleCtrlClick({
        type,
        id
      })
    };

    const tree = useMemo(() => {
      return shcemaData && shcemaData.uvMap
        ? transformToTreeNode(JSON.parse(shcemaData.uvMap))
        : transformToTreeNode("");
    }, [shcemaData]);
    return (
      <>
        {/* 测试用 */}
        {/* <ShcemaDataPreview shcemaData={shcemaData} uvMode={uvMode} /> */}
        <section className="formily-preview-content">
          {shcemaData.uvType == "MOBILE" ? (
            <div className="formily-preview-content-wrapper">
              <div
                style={{
                  transform: `scale(${scaleNum})`,
                  transformOrigin: scaleNum <= 1 ? "center" : "top center",
                }}
                className="formily-preview-content-mobile"
              >
                <img
                  className="mobile-img"
                  src="//img.alicdn.com/imgextra/i4/O1CN01ehfzMc1QPqY6HONTJ_!!6000000001969-55-tps-459-945.svg"
                  alt=""
                />
                <div className="mobile-content">
                  <div
                    style={{
                      overflow: "overlay",
                      height: "100%",
                      cursor: "auto",
                      userSelect: "text",
                    }}
                  >
                    <Iframe
                      ref={iframeRef}
                      tree={tree}
                      changeData={pushToHistory}
                      handleCtrlClick={handleCtrlClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <PreviewWidget
              {...props}
              uvMode={uvMode}
              handleCtrlClick={handleCtrlClick}
              handlNavigate={handlNavigate}
              tree={tree}
              initParms={jsonObject}
            />
          )}
        </section>
        <EventModal ref={modalRef} />
      </>
    );
}


export default PreviewContent;
