import React from 'react';
import inlineSvg from '@/script/inlineSvg.mjs'

const LEG = 12
// 平行六边形SVG组件（左右直角六边形）
const Hexagon = (w=60, h=30, bgColor = '#fff', borderColor="#50c3f7") => {
  const bw = 1
  const paddings = [bw, bw + LEG, bw, bw + LEG]
  
  return inlineSvg(
  `<svg 
    xmlns="http://www.w3.org/2000/svg"
    fill="${bgColor}"
    stroke="${borderColor}"
    stroke-width="1"
  >
    <path
      d="
        M ${bw / 2} ${h / 2}
        L ${bw / 2 + LEG} ${bw / 2}
        H ${w - bw / 2 - LEG}
        L ${w - bw / 2} ${h / 2}
        L ${w - bw / 2 - LEG} ${h - bw / 2}
        H ${bw / 2 + LEG}
        Z
      "
    />
  </svg>`
)};

export default Hexagon;
