import React from 'react';
// import { Table } from 'antd';
import openWindow from '@/script/openWindow.mjs';
import Page from '@/script/Page';
import Keyword from './Keyword.jsx';
import Table from './SearchTable';

const Pane = ({ param, dataList, rows, setRows, loading }) => {
    const keyword = param.current.keyword;
    const prjPatternId = param.current.prjPatternId;
    const patternRev = param.current.patternRev;

    const onLink = (record) => {
        const mapInits = {
            selectedNodes: [{ path: record.mapPath }],
        }

        if (record.mapVerId) {
            const inits = { ...mapInits };
            openWindow('/SoftwareMap', { id: record.mapVerId, mode: 'snapshot', inits, prjPatternId, patternRev });
        } else {
            openWindow('/DtDetail', { dtId: record.dpId, mapInits });
        }
    }

    const columns = [
        {
            key: 'dmNamePath',
            dataIndex: 'dmNamePath',
            title: '库表域',
            width: '100px'
        },
        {
            key: 'dpUserCode',
            dataIndex: 'dpUserCode',
            title: '表代码',
            width: '100px'
        },
        {
            key: 'dpName',
            dataIndex: 'dpName',
            title: '表名称',
            width: '100px'
        },
        {
            key: 'keywordPath',
            dataIndex: 'keywordPath',
            title: '节点',
            onCell: (record, index) => {
                return { style: { whiteSpace: 'wrap' } };
            },
            render: (text, record) => {
                // record.mapPath = '/' + encodeURIComponent(record.dpName) + record.keywordEncodePath;
                record.mapPath = record.keywordEncodePath;
                return <a onClick={() => onLink(record)}><Keyword data={text.substr(0, text.length - 1)} keyword={keyword} /></a>;
            }
        }
    ]

    const rowSelection = {
        selectedRowKeys: rows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setRows(selectedRows);
        },
    }

    return (
        <Table
            size="small"
            loading={loading}
            columns={columns}
            dataSource={dataList}
            pagination={Page.init(dataList)}
            rowKey={record => record.pkid}
            rowSelection={{ type: "checkbox", ...rowSelection }}
        />
    );
}

export default Pane;
