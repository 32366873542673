import FormilyPreview from './FormilyPreview.jsx';
import FormilyMobile from './FormilyMobile.jsx'
// import FormilyFrame from './FormilyFrame.jsx';
import FormilyPreviewMobile from './FormilyPreviewMobile.jsx'
import {useUser} from '@/biz/user.mjs'
import {redirect } from 'react-router-dom'

export default () => {
    const user = useUser()

    const loader = async ({request: {url}}) => {
        const {pathname, search} = new URL(url)

        if (! user) {
            if ('/' !== pathname) {
                const r = encodeURIComponent(pathname + search)
                return redirect(`/login?r=${r}`)
            }
            else {
                return redirect('/login')
            }
        }

        if ('/' !== pathname) {
            return null
        }

        return redirect("/Workbench")
    }
    return [{
        path: '/FormilyPreview',
        loader,
        element: <FormilyPreview />,
    },
    {
        path: '/FormilyMobile',
        element: <FormilyMobile />,
    },{
        path: 'FormilyPreviewMobile',
        element:<FormilyPreviewMobile/>,
    },
    // {
    //     path: 'FormilyFrame',
    //     element:<FormilyFrame />,
    // }
    ]
}