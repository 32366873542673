export default [
    {
        title: '项目管理',
        children: [
            {
                title: '项目',
                path: 'ProjectList',
            },
            {
                title: '需求单',
                path: 'RequirementFormList',
            },
            {
                title: '需求单（银行业）',
                path: 'BrfList',
            },
            {
                title: '开发任务单',
                path: 'DesList',
            },
            {
                title: '上线单',
                path: 'PrdVersionList',
            },
        ],
    },

    {
        title: '模型管理',
        children: [
            {
                title: '模型分支',
                path: 'PrjBaseLineList',
            },
            {
                title: '模型成品',
                path: 'PrjPatternList',
            },
            {
                title: '模型集成图',
                children: [
                    {
                        title: '模型总图',
                        path: 'SaList',
                    },
                    {
                        title: '组织地图',
                        path: 'ProjectOrgMap',
                        checkPrjType: true,
                    },
                    {
                        title: '流程地图',
                        path: 'ProjectBfMap',
                        checkPrjType: true,
                    },
                    {
                        title: '业务地图',
                        path: 'ProjectBizMap',
                        checkPrjType: true,
                    },
                    {
                        title: '分析地图',
                        path: 'ProjectAnaMap',
                    },
                    {
                        title: '数据地图',
                        path: 'ProjectDataMap',
                    },
                    {
                        title: '功能地图',
                        path: 'ProjectSfMap',
                        checkPrjType: true,
                    },
                    {
                        title: '界面地图',
                        path: 'ProjectUiMap',
                        checkPrjType: true,
                    },
                    {
                        title: '接口地图',
                        path: 'ProjectInfMap',
                        checkPrjType: true,
                    },
                    {
                        title: '用例地图',
                        path: 'ProjectUcMap',
                        checkPrjType: true,
                    },
                    {
                        title: '库表地图',
                        path: 'ProjectDbMap',
                        checkPrjType: true,
                    },
                    {
                        title: '程序地图',
                        path: 'ProjectFcMap',
                        checkPrjType: true,
                    }
                ]
            },
            {
                title: '模型模件',
                path: 'ModuleList',
            },
            {
                title: '模型制品',
                path: 'DpList',
            },

            {
                title: '分类制品',
                children: [
                    {
                        title: '组织制品',
                        children: [
                            {
                                title: '组织结构',
                                path: 'OaList',
                            },
                            {
                                title: '组织对象',
                                path: 'OoList',
                            },
                        ]
                    },

                    {
                        title: '流程制品',
                        children: [
                            {
                                title: '流程结构',
                                path: 'PaList',
                            },
                            {
                                title: '业务流程',
                                path: 'BfList',
                            },
                        ]
                    },

                    {
                        title: '业务制品',
                        children: [
                            {
                                title: '业务结构',
                                path: 'BaList',
                            },
                            {
                                title: '业务能力模块',
                                path: 'BdList',
                            },
                        ]
                    },

                    {
                        title: '分析制品',
                        children: [
                            {
                                title: '分析结构',
                                path: 'AaList',
                            },
                            {
                                title: '维度对象',
                                path: 'DoList',
                            },

                            {
                                title: '分析对象',
                                path: 'AoList',
                            },

                            {
                                title: '分析指标',
                                path: 'AmAiList',
                            },
                            {
                                title: '分析报表',
                                path: 'AmArList',
                            },
                        ]
                    },

                    {
                        title: '功能制品',
                        children: [
                            {
                                title: '功能结构',
                                path: 'FaList',
                            },
                            {
                                title: '系统功能',
                                path: 'SfList',
                            },
                        ]
                    },

                    {
                        title: '界面制品',
                        children: [
                            {
                                title: '界面结构',
                                path: 'UaList',
                            },
                            {
                                title: '用户界面',
                                path: 'UiList',
                            },
                        ]
                    },

                    {
                        title: '接口制品',
                        children: [
                            {
                                title: '外联系统',
                                path: 'LsList',
                            },
                            {
                                title: '外联接口',
                                path: 'LsiList',
                            },
                        ]
                    },

                    {
                        title: '用例制品',
                        children: [
                            {
                                title: '用例结构',
                                path: 'CaList',
                            },
                            {
                                title: '集成用例',
                                path: 'UcList',
                            },
                        ]
                    },

                    {
                        title: '库表制品',
                        children: [
                            {
                                title: '数据库',
                                path: 'DbList',
                            },
                            {
                                title: '数据表',
                                path: 'DtList',
                            },
                            {
                                title: '数据视图',
                                path: 'DvList',
                            },
                            {
                                title: '定制数据类型',
                                path: 'CdtList',
                            },
                            {
                                title: '标准字段',
                                children: [
                                    {
                                        title: '标准字段组',
                                        path: 'DmPcdgList',
                                    },
                                    {
                                        title: '引用标准的标准字段',
                                        path: 'DmPcdgDfByCdgList',
                                    },
                                    {
                                        title: '引用标准的表字段',
                                        path: 'DmPcdgDfByDtList',
                                    },
                                ]
                            },
                        ]
                    },

                    {
                        title: '程序制品',
                        children: [
                            {
                                title: '子系统',
                                path: 'SsList',
                            },
                            {
                                title: '程序能力',
                                path: 'FcList',
                            },
                            {
                                title: 'DTO',
                                path: 'DtoList',
                            },
                            {
                                title: '中间件',
                                path: 'ArchMiddlewareList',
                            },
                        ]
                    },
                    {
                        title: '部署制品',
                        children: [
                            {
                                title: '物理机',
                                path: 'DepPsList',
                            },
                            {
                                title: '虚拟机',
                                path: 'DepVsList',
                            },
                        ]
                    },
                ]
            },

            {
                title: '业务组件生成记录',
                path: 'BctRecord',
            },
        ]
    },

    {
        title: '资源库',
        code: 'TemplateLibrary',
        children: [
            {
                title: '业务组件库',
                code: 'BizComponentTemplateLibrary',
                path: 'BizComponentTemplateLibrary',
            },
            {
                title: '制品模板库',
                code: 'DesignProductTemplateLibrary',
                path: 'DesignProductTemplateLibrary',
            },
        ],
    },

    {
        title: '团队管理',
        code: 'TM',
        children: [
            {
                title: '个人号',
                path: 'UserList',
            },
            {
                title: '团队号',
                path: 'TeamList',
            },
            {
                title: '团队库',
                code: 'TmLibrary',
                children: [
                    {
                        title: '资源',
                        path: 'TeamBctList',
                    },
                    {
                        title: '界面视图模板',
                        path: 'TeamUvTemplateList',
                    },
                ],
            },
        ],
    },

    {
        title: '平台管理',
        code: 'SM',
        children: [
            {
                title: '系统配置',
                path: 'SmSet',
            },
            {
                title: '智能运维',
                path: 'AiOperation',
            },
        ],
    },
]
